const ArrowDownNew = (props) => (
  <svg xmlns='http://www.w3.org/2000/svg' width={11.73} height={6.865} {...props}>
    <path
      data-name='Path 2091'
      d='M0 1A1 1 0 0 1 .293.293a1 1 0 0 1 1.414 0l4.158 4.158L10.023.293a1 1 0 0 1 1.414 0 1 1 0 0 1 0 1.414L6.572 6.572a1 1 0 0 1-1.414 0L.292 1.707A1 1 0 0 1 0 1Z'
      fill={props.color !== undefined ? props.color : '#29D7DF'}
    />
  </svg>
);

export default ArrowDownNew;
